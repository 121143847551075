import * as React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Layout from '../../../components/Layout';

function BlogsPage({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) {
  return (
    <Layout>
      <section className="section">
        <Helmet title={`News Tags | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1" style={{ marginBottom: '6rem' }}>
              <h1 className="title is-size-2 is-bold-light">News Tags</h1>
              <ul className="taglist">
                {group.map((tag) => (
                  <li key={tag.fieldValue}>
                    <Link to={`/tags/news/${kebabCase(tag.fieldValue)}/`}>
                      {tag.fieldValue}
                      {' '}
                      (
                      {tag.totalCount}
                      )
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BlogsPage;

export const blogPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000, filter: { frontmatter: { templateKey: { eq: "news-link" } } }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
